window.$ = window.jQuery = require('jquery');
window.Cookies = require('js-cookie');

require('./vendor/jqueryUI');

require('./vendor/doubleTapToGo');
require('./vendor/fancybox');
require('slick-carousel');
require('./vendor/match-height');
require('./scripts/cookie');
require('./scripts/event');

import {Fef} from './classes/Fef';
import {FormElements} from './classes/FormElements';

// Webshop includes
import section from './scripts/section';
import {Basket} from './classes/Basket';
import articles from './scripts/articles';

/**
 * Main Scripts
 *
 * This main scripts file is where you'll write most
 * of your (jQuery) scripts.
 */

(function ($) {
    /**
     * Initialise the Front-end Framework.
     *
     * @type {Fef}
     */
    let fef = new Fef({
        matchHeightSite: () => {
            $('.news .item h2').matchHeight({
                byRow: true
            });
            $('.news .item .content__box').matchHeight({
                byRow: true
            });
            $('.dealers .item h3').matchHeight({
                byRow: true
            });
            $('.categories .item').matchHeight({
                byRow: true
            });
            $('.categories .item .content__box').matchHeight({
                byRow: true
            });
            $('.categories .item .content__box h2').matchHeight({
                byRow: true
            });
            $('.categories .item .content__box').matchHeight({
                byRow: true
            });
            $('.categories__section .item').matchHeight({
                byRow: true
            });
            $('.categories__section .item .inner').matchHeight({
                byRow: true
            });
            $('.categories__section .item .content__box h2').matchHeight({
                byRow: true
            });
            $('.categories__section .item .content__box').matchHeight({
                byRow: true
            });
        },
        shop: {
            matchHeightShopArticles: () => {
                matchHeightArticles();
            }
        },
    });

    // Webshop
    Basket.init();

    function initSection() {
        section({
            afterPartialIsLoaded: {
                details: function () {
                    FormElements.floatingLabel('section[data-section^="shop."] .inputfield input');
                },
                address: function () {
                    FormElements.floatingLabel('section[data-section^="shop."] .inputfield input');
                },
                addresses: function () {
                    FormElements.floatingLabel('section[data-section^="shop."] .inputfield input');
                },
                shippingMethods: function () {
                    FormElements.floatingLabel('section[data-section^="shop.checkout"] .inputfield input');
                },
                orderFields: function () {
                    FormElements.floatingLabel('section[data-section^="shop.checkout"] .inputfield input');
                },
                addressBook: function () {
                    FormElements.floatingLabel('section[data-section^="shop.checkout"] .inputfield input');
                },
                totals: function () {
                    FormElements.floatingLabel('section[data-section^="shop.checkout"] .inputfield input');
                    Basket.bindCouponLink();
                },
                articles: function () {
                    matchHeightArticles();
                },
                filters: function () {
                    fef.shop.filters.initFilters();
                }
            },
            afterAction: {
                navigate: function (e, data) {
                    $('html,body').animate({
                        scrollTop: $(".cms-shop-articles").offset().top - 100
                    }, 500);
                },
                addToBasket: function (e) {
                    let id = $(e.currentTarget).data('id');
                    let articleContainer = $('[data-action="addToBasket"][data-id=' + id + ']').closest('[data-added-to-cart]');

                    articleContainer.addClass("addedtocart");
                    setTimeout(() => {
                        articleContainer.removeClass("addedtocart");
                    }, 1000);

                    let cart = $('.header .cart');
                    cart.addClass('addedtocart');
                    setTimeout(() => {
                        cart.removeClass("addedtocart");
                    }, 1000);
                }
            }
        });
        articles({
            timeoutInfoMessages: 4000
        });
    }
    initSection();

    function matchHeightArticles() {
        $('.product__item .info').matchHeight({
            byRow: true
        });
        $('.content__menu .inner .info h2').matchHeight({
            byRow: true
        });
        $('.product__item .info h2').matchHeight({
            byRow: true
        });
        $('.product__item .info h3').matchHeight({
            byRow: true
        });
        $('.content__menu .inner .info').matchHeight({
            byRow: true
        });
        $('.product .matchingcolumns').matchHeight({
            byRow: true
        });
        $('.cms__shopuser .user__col .bg .inner').matchHeight({
            byRow: true
        });
        $('.product__item-favorites .inner').matchHeight({
            byRow: true
        });
        $('.product-item_favorite .inner').matchHeight({
            byRow: true
        });
        $('.product-item_favorite .inner .info').matchHeight({
            byRow: true
        });
    }

    // Fancybox
    $(".fancybox, .lightbox").fancybox({
        // Options will go here
    });

    //FAQ Toggle
    $('.faq .open-toggle').hide();
    $('.faq .open').show();
    $('.faq .closed').hide();
    $(".faq .toggle").click(function () {
        $(this).closest('.toggle-box').find(".open-toggle").slideToggle("slow", function () {
            if ($(this).closest('.toggle-box').hasClass('active')) {
                $(this).closest('.toggle-box').removeClass('active');
                $(this).closest('.toggle-box').find('.closed').hide();
                $(this).closest('.toggle-box').find('.open').show();
            } else {
                $(this).closest('.toggle-box').addClass('active');
                $(this).closest('.toggle-box').find('.closed').show();
                $(this).closest('.toggle-box').find('.open').hide();
            }
        });
    });

    //hide accountmenu on mobile / toggle it
    $('.account__menu h2').off('click').on('click', function () {
        var ToggleFilter = $('.js-mobile-menu .nav__account');
        var ToggleFilterButton = $('.js-mobile-menu h2');

        if (ToggleFilter.hasClass('js-mobile-menu-active')) {
            ToggleFilter.removeClass('js-mobile-menu-active');
            ToggleFilterButton.removeClass('js-mobile-menu-active');
        } else {
            ToggleFilter.addClass('js-mobile-menu-active');
            ToggleFilterButton.addClass('js-mobile-menu-active');
        }
        return false;
    });

    // Toggle for hidden rows in account tables
    $('.table__row .hidden__rows').hide();
    $('.table__row .hidden__rows.active').show();

    $(".table__row .toggler").click(function () {
        $(this).closest('.table__row').find(".hidden__rows").slideToggle("slow", function () {
            if ($(this).closest('.hidden__rows').hasClass('active')) {
                $(this).closest('.hidden__rows').removeClass('active');
            } else {
                $(this).closest('.hidden__rows').addClass('active');
            }
        });
    });


    // Close notification for 1 day
    if ($('.notification .inner').length > 0) {
        $('.js-notification-close').click(function () {
            Cookies.set('notification', false);

            $('.notification').hide();
            $('body').removeClass('has-notification');
        });

        if (Cookies.get('notification') == 'false') {
            $('.notification').hide();
            $('body').removeClass('has-notification');
        } else {
            $('.notification').show();
            $('body').addClass('has-notification');
        }
    }

    if ($('.product__item .prices .oldprice').length > 0) {
        $('.product__item .prices .oldprice').addClass('u-margin-none');
    } else {
        $('.product__item .prices .oldprice').removeClass('u-margin-none');
    }

    // Open forms based on anchor in URL hash
    var urlHash = window.location.hash;

    if (urlHash.indexOf("_anchor_5") >= 0) {
        setTimeout(function () {
            $("#triggerform").trigger("click");
        }, 100);
    }

})(jQuery);