class Sliders {
    constructor(config) {
        if (config.sliders.hero) {
            this.hero();
        }

        if (config.sliders.brands) {
            this.brands();
        }

        if (config.sliders.brands) {
            this.productslider();
        }
    }

    hero() {
        $('.js-hero-slider').slick({
            infinite: true,
            speed: 1000,
            fade: true,
            cssEase: 'linear',
            lazyLoad: 'ondemand',
            arrows: true,
            autoplay: true,
            rows: 0,
            dots: false,
            autoplaySpeed: 4000,
            prevArrow: $('.prevArrow'),
            nextArrow: $('.nextArrow')
        });
    }

    brands() {
        $('.js-brandslider section').slick({
            infinite: true,
            lazyLoad: 'ondemand',
            slidesToShow: 5,
            slidesToScroll: 1,
            pauseOnHover: false,
            swipeToSlide: true,
            focusOnSelect: true,
            arrows: false,
            rows: 0,
            dots: false,
            autoplay: true,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            }, {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                }
            },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }]
        });
    }

    productslider() {
        $('.js-productslider').slick({
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            pauseOnHover: false,
            swipeToSlide: true,
            focusOnSelect: true,
            arrows: true,
            prevArrow: $('.prev'),
            nextArrow: $('.next'),
            rows: 0,
            dots: false,
            autoplay: true,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            }, {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }, {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        });
    }
}

export {Sliders};