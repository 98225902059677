/**
 * PLEASE DO NOT MODIFY THIS FILE!
 *
 * This is the main class of the Fef project. It will initialise ans enable the default functionality
 * depending on the default and project config files.
 * To alter the functionality, please modify the project config file.
 */

import configDefault from "../config/default";
import config from "../config/project";

/**
 * Front-end Framework main module class. This class contains the main shop functionality of the Fef.
 */
class AbstractModule {
    constructor(callbacks, initialCallbacks = []) {
        this.config = $.extend(configDefault, config);

        this.callbacks = callbacks;

        this.init();

        /**
         * Call the initial callbacks.
         */
        for (let i = 0; i < initialCallbacks.length; i++) {
            this.callback(initialCallbacks[i]);
        }
    }

    callback(callback) {
        if (this.callbacks.hasOwnProperty(callback)) {
            this.callbacks[callback]();
        }
    }

    init() {
    }
}

export {AbstractModule}