export default function (options) {
    let defaults = {
            timeoutInfoMessages: 0,
        },
        settings = $.extend(defaults, options);

    // Info Message fadeout
    if (settings.timeoutInfoMessages > 0) {
        setTimeout(function () {
            $('.info.message').fadeOut('fast');
        }, settings.timeoutInfoMessages);
    }

    function amountQty(e, plusOrMinus) {
        e.preventDefault();

        let field = $(e.target).parent().find('input.amount');
        let orderPer = $(field).data('order-per') ? parseFloat($(field).data('order-per')) : 1;
        let currentVal = parseFloat($(field).val());
        let newAmount;

        if (plusOrMinus === 'minus') {
            let minAmount = $(field).data('min-amount') ? parseFloat($(field).data('min-amount')) : 1;
            newAmount = currentVal - orderPer;
            newAmount = newAmount < minAmount ? minAmount : newAmount;
        } else {
            let maxAmount = $(field).data('max-amount') ? parseFloat($(field).data('max-amount')) : null;
            newAmount = currentVal + orderPer;
            newAmount = (maxAmount && (newAmount > maxAmount)) ? maxAmount : newAmount;
        }

        let precision = $(field).data('precision') ? parseFloat($(field).data('precision')) : 0;
        $(field).attr('value', newAmount.toFixed(precision));
        $(field).val(newAmount.toFixed(precision)).trigger('change');
    }

    $('.qtyplus').off('click').click(function (e) {
        amountQty(e, 'plus');
    });
    $('.qtyminus').off('click').click(function (e) {
        amountQty(e, 'minus');
    });

    // Articles image gallery
    // Preview image
    $('.js-gallery .inner').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
        arrows: false,
        draggable: false,
        dots: false,
        fade: true,
        asNavFor: '.js-gallery-nav',
        adaptiveHeight: true
    });

    // Thumbnail images
    $('.js-gallery-nav').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 0,
        swipeToSlide: true,
        focusOnSelect: true,
        centerMode: false,
        asNavFor: '.js-gallery .inner',
        prevArrow: $('.prevArrow'),
        nextArrow: $('.nextArrow'),
        vertical: false,
        responsive: [
            {
                breakpoint: 4000,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 990,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }]
    });

    // Article image enlargement
    $('[data-fancybox="gallery"]').fancybox({
        loop: true,
        afterShow: function (instance, slide) {
            $('.js-gallery').slick('slickGoTo', slide.index);
            $('.js-gallery-nav').slick('slickGoTo', slide.index);
        },
        buttons: [
            'slideShow',
            'fullScreen',
            'thumbs',
            'close'
        ]
    });

    //scroll at product detail
    $.fn.matchHeight._afterUpdate = function (event, groups) {
        if ($('.webshop__article .article__scroll').length) {
            // Sidebar scroll
            var articleScroll = $('.webshop__article .article__scroll');
            var articleScrollHeight = $(articleScroll).outerHeight();
            var footerFromTop = ($('.accessoiries').offset().top - 0);
            var articleScrollAbsolutePosition = ((footerFromTop - articleScrollHeight) - articleScrollHeight);

            $(articleScroll).outerWidth($(articleScroll).parents('.webshop__article .prod__info').width());

            $(window).scroll(function () {
                if ($(window).scrollTop() > articleScrollAbsolutePosition) {
                    $(articleScroll).addClass('absolute');
                } else {
                    $(articleScroll).removeClass('absolute');
                }
            });
        }
    };

    // Product Anchors
    $(".js-anchors .anchor__item .anchor__link").click(function () {
        $(this).parent().addClass('selected');
        $(this).parent().siblings().removeClass('selected');
    });

    //Filters toggle mobile
    $('.filtertogglebutton').off('click').on('click', function () {
        var ToggleFilter = $('.filter');
        var ToggleFilterButton = $('.filtertogglebutton');

        if (ToggleFilter.hasClass('active')) {
            ToggleFilter.removeClass('active');
            ToggleFilterButton.removeClass('reverse active');
        } else {
            ToggleFilter.addClass('active');
            ToggleFilterButton.addClass('reverse active');
        }
        return false;
    });

}