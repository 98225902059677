class Navigation {
    constructor(config) {
        this.config = config;
        this.init();
    }

    init() {
        let self = this;

        /**
         * When clicking on the Open/Close icon,
         * this'll add and remove various classes for you to reference in your css.
         */
        $('.js-menu-toggle').click(function () {
            $(this).toggleClass('is-active');

            $('html').toggleClass('no-scroll');
            $('body').toggleClass('menu-is-active').toggleClass('no-scroll');

            $('.has-sub').removeClass('submenu-is-active');
            $('.js-submenu-toggle').removeClass('is-active');
        });

        /**
         * When clicking on the sub-menu icon,
         * this'll add and remove various classes for you to reference in your css.
         */
        $('.js-submenu-toggle').click(function () {
            $(this).toggleClass('is-active');

            $('.js-submenu-toggle').not($(this)).removeClass('is-active');

            $('.submenu-is-active').not($(this).parents('.has-sub')).removeClass('submenu-is-active');

            $(this).parent('.has-sub').toggleClass('submenu-is-active');
        });

        /**
         * When clicking anywhere but one of the mentioned targets,
         * this will remove the active states of all elements,
         * effectively hiding the navigation.
         */
        $(window).click(function (e) {
            let target = e.target;

            if ($('body').hasClass('menu-is-active')
                && !$(target).is('.js-menu-toggle')
                && !$(target).is('.hamburger-inner')
                && !$(target).is('.js-submenu-toggle')
                && !$(target).is('.fa')
                && !$(target).is('nav')
                && !$(target).is('nav input')
                && !$(target).is('.is-moved')
                && !$(target).is('.buttontoggle')
                && !$(target).is('.is-moved *')
                && !$(target).is('nav button')) {
                self.removeMenuActiveState();
            }
        });

        /**
         * When clicking Esc on your keyboard this will remove the active states of all elements,
         * effectively hiding the navigation.
         */
        $(window).keyup(function (e) {
            let which = e.which;

            if ($('body').hasClass('menu-is-active') && (which === 27)) {
                self.removeMenuActiveState();
            }
        });

        /**
         * When resizing the browser to a width greater than the given value
         * this will remove the active states of all elements, effectively hiding the navigation.
         */
        $(window).resize(function () {
            if ($(window).outerWidth() > self.config.breakpoints.md) {
                self.removeMenuActiveState();
            }
        });

        /**
         * If the given item(s) have a UL child,
         * add a class to represent this.
         */
        $('.nav__item').has('ul').addClass('has-sub');
    }

    /**
     * Remove all classes, effectively hiding the navigation.
     */
    removeMenuActiveState() {
        $('body').removeClass('no-scroll');
        $('html').removeClass('no-scroll');
        $('body').removeClass('menu-is-active');
        $('.has-sub').removeClass('submenu-is-active');
        $('.js-menu-toggle').removeClass('is-active');
        $('.js-submenu-toggle').removeClass('is-active');
    }
}

export {Navigation};