import {FormElements} from "./FormElements";

class Basket {
    static init() {
        // User login/register tabs
        $('.cart_option__user').click(function () {
            $('.cart_option__user').removeClass('current');

            $(this).addClass('current');
            $("#" + $(this).attr('data-tab')).addClass('current');
        });

        this.bindCouponLink();
    }

    /**
     * Bind coupon show ling.
     */
    static bindCouponLink() {
        $(".cart__vouchers h4, .small__coupons h4").click(function () {
            $(this).toggleClass('u-hidden');
            $(this).closest('section').find('.inputfield').toggleClass('u-hidden');
        });

        FormElements.floatingLabel('.cart__vouchers .inputfield');
    }
}

export {Basket};