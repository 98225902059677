window.syvEvent = function() {
    var self = window;

    self.queue = {};
    self.fired = [];

    return {
        fire: function(event) {
            var queue = self.queue[event];

            if (typeof queue === 'undefined') {
                return;
            }

            queue.forEach(function (callback) {
                callback();
            });

            self.fired[event] = true;
        },

        on: function(events, callback) {
            if (!(events instanceof Array)) {
                events = [events];
            }

            events.forEach(function (event) {
                if (self.fired[event] === true) {
                    return callback();
                }

                if (typeof self.queue[event] === 'undefined') {
                    self.queue[event] = [];
                }

                self.queue[event].push(callback);
            });
        }
    };
}();
